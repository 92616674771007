<template>
    <OModal v-bind="$attrs">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content rounded-0">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('Publish to Org Units') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <ODataGrid :dataObject="dataObject" style="max-height: 850px; overflow-y:auto" hideGridMenu hideMultiselectColumn loadDataObject>
                        <OColumn field="OrgUnit" flexwidth="300" :headerName="$t('Org unit')" sortable editable>
                            <template #editor="{ modelValue: row}">
                                <OOrgunitsLookup textInput v-model="row.OrgUnit" :bind="sel=>{row.OrgUnit = sel.Name; row.OrgUnit_ID = sel.ID}"/>
                            </template>
                        </OColumn>
                        <OColumn v-if="dataObject.current.ExcludeSubUnits !=undefined" field="ExcludeSubUnits" width="120" :headerName="$t('Exclude sub units')" sortable editable />
                        <OColumn v-if="dataObject.current.UnitType !=undefined" field="UnitType" width="120" :headerName="$t('Unit type')" sortable editable />
                        <OColumn v-if="dataObject.current.DoNotInherit !=undefined" field="DoNotInherit" width="120" :headerName="$t('Do Not Inherit')" sortable editable />
                        <OColumn v-if="dataObject.current.ParentOrgUnit !=undefined" field="ParentOrgUnit" width="120" :headerName="$t('Parent Org Unit')" sortable editable />
                    </ODataGrid>
                </div>
            </div>
        </div>
    </OModal>
</template>
<script setup>
    import OOrgunitsLookup from "o365.vue.components.OrgUnitsLookup.vue";

    const props = defineProps(['dataObject']);
    if(props.dataObject === undefined) {
        console.log('dataObject not passed into PublishToOrgUnitModal')
    }
</script>